import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  GetApprovedReviewsForExpert,
  GetExperts,
  UpdateExpert,
} from '@queries';
import {
  Expert,
  ExpertReviewsResponse,
  ExpertsResponse,
  ExpertUpdateResponse,
  RequestStatus,
} from '@types';
import apolloClient from 'api/apollo-client';
import { RootState } from '@store';

export const expertsAdapter = createEntityAdapter<Expert>();

export const expertsThunk = createAsyncThunk('api/experts', async _ => {
  const response = await apolloClient.query<ExpertsResponse>({
    query: GetExperts,
  });
  return response.data.experts.data;
});

export const expertReviewsThunk = createAsyncThunk(
  'api/expertReviews',
  async (expertId: number) => {
    const response = await apolloClient.query<ExpertReviewsResponse>({
      query: GetApprovedReviewsForExpert,
      variables: { id: expertId },
    });
    return response.data.expert.reviews;
  },
);

export const updateExpertThunk = createAsyncThunk(
  'api/updateExpert',
  async (expertId: number) => {
    const response = await apolloClient.query<ExpertUpdateResponse>({
      query: UpdateExpert,
      variables: { id: expertId },
    });
    return response.data.expert;
  },
);

const selectExperts = (state: RootState) => state.experts;
export const {
  selectAll: selectAllExperts,
  selectEntities: selectEntitiesExperts,
  selectById: selectExpertById,
} = expertsAdapter.getSelectors(selectExperts);

export const expertsSlice = createSlice({
  name: 'experts',
  initialState: {
    ...expertsAdapter.getInitialState(),
    selectedExpert: null as Expert | null,
    status: RequestStatus.NoRequested,
  },
  reducers: {
    setSelectedExpert: (state, action) => {
      state.selectedExpert = action.payload;
    },
    emptySelectedExpert: (state, _) => {
      state.selectedExpert = null;
    },
    updateExpertPartial: (state, action) => {
      expertsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          availability: action.payload.availability,
          inProcessCall: action.payload.inProcessCall,
        },
      });
    },
  },
  extraReducers(builder) {
    // Get experts
    builder.addCase(expertsThunk.pending, (state, _) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(expertsThunk.fulfilled, (state, action) => {
      expertsAdapter.setAll(state, action.payload);
      state.status = RequestStatus.Fulfilled;
    });
    builder.addCase(expertsThunk.rejected, (state, _) => {
      state.status = RequestStatus.Rejected;
    });
    // Update expert
    builder.addCase(updateExpertThunk.pending, (state, _) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(updateExpertThunk.fulfilled, (state, action) => {
      expertsAdapter.upsertOne(state, action.payload);
      state.status = RequestStatus.Fulfilled;
    });
    builder.addCase(updateExpertThunk.rejected, (state, _) => {
      state.status = RequestStatus.Rejected;
    });
  },
});

export const { setSelectedExpert, emptySelectedExpert, updateExpertPartial } =
  expertsSlice.actions;

export default expertsSlice.reducer;
